
.nav-container{
    border-bottom: solid 0.5px;
    z-index: 2;
    display: flex;
    position:fixed;
    top:0;
    width: 100vw;
    background-color: rgba(211, 84, 0);
    height:50px;
    nav{
        display: inherit;
        margin-right:5px;
        width:100vw;
        .licNum{
            margin-left:5px;
            font-size:0.7rem;
            align-self: center;
        }
        .phone-container{
            display:flex;
            position: absolute;
            top:5%;
            left:45%;
            :hover{
                transform: scale(1.1, 1.1);
            }
        }
        .phone{
            color:black;
            width: fit-content;
            font-size: 1rem;
            margin-top:.7rem;
            padding-left:.3rem;
            margin-right:auto;
            white-space: nowrap;
        }
        .nav-links{
            :hover{
                transform: scale(1.1, 1.1);
            }
            a{color:black;
                }
            display: flex;
            width:35vw;
            max-width:300px;
            margin-left:auto;
            justify-content: space-around;
            transform: translate(0,.7rem);
            @media(max-width:768px){
                visibility: collapse;
            }
    }

    .menu-icon{
        z-index: 1;
        position: absolute;
        display: flex;
        flex-direction: column;
        padding: 20px;
        top: -15px;
        right: 20px;
        width:10px;
    }
    }
}
.treeLogo{
    position:relative;
    margin-left:0.5%;
    max-height: inherit;
    float: left;
    padding:3px;
}
@media(min-width:767px){
        .nav-container{
            nav{
                display: flex;
                margin-right:5px;
                width:100vw;
                .nav-links{
                    margin-right: 3%;
                    margin-top:-0.3%;
                    font-size: 1.5rem;
                }
                .licNum{
                    margin-left:10px;
                }
                .menu-icon{
                    visibility: collapse;
                }
                .phone-container{
                    display:flex;
                    position: absolute;
                    top:0%;
                    left:20%;
                    .phone{
                        color:black;
                        width: fit-content;
                        font-size: 1.3rem;
                        margin-top:.7rem;
                        padding-left:.3rem;
                        margin-right:auto;
                        white-space: nowrap;
                      
                    }
                    :hover{
                        transform: scale(1.1, 1.1);
                    }
                }
            }
        }
    }
    @media(min-width:1299px){
        .nav-container{
            height: 60px;
            nav{
                .licNum{
                    font-size:1rem;
                }
                .nav-links{
                    gap:25%;
                    margin-right:7%;
                    margin-top:0;
                }
                .phone-container{
                    display:flex;
                    position: absolute;
                    top:10%;
                    left:15%;
                }
            }
        }
    }
    