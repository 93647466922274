.dropDown{  
  position: absolute;
  width:65vw;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  background-color: rgba(0,0,0,0.90);
  border-radius:(25px);
  top: 40px;
  right: 1px;
  // z-index: 5;
  
    :hover{
      transform:scale(1.5);
    }
    .dropA{
      
    font-size: 1.5rem;       
    color:rgba(211, 84, 0);
    line-height: 4rem;
      }
    }
  
  
  
