

.aboutUs-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
padding: 1px 5vw 1px 5vw;
    max-height: fit-content;
    width:90vw;
    background-image: linear-gradient(to bottom, rgba(0,0,0,1)0%, rgba(0,0,0,.7)10%),
    linear-gradient((to bottom,rgba(0,0,0,.5)80%,rgba(0,0,0,1) )100%) ,
    url('../../utility/backgrounds/olena-sergienko-3BlVILvh9hM-unsplash.jpg');
    background-size:cover;
    h2{
        font-size: 3.5rem;
        color:whitesmoke;
    }
    h3{
        color:whitesmoke;
        font-size: 1.5rem;
        text-align: center;
    }
    p{  color: whitesmoke;
        font-size:1.3rem;
        line-height: 3vh;
        text-align: center;
    }
   
}
@media(min-width:767px){
    .aboutUs-container{
        p{  color: whitesmoke;
            font-size:1.9rem;
            line-height: 5vh;
            text-align: center;
        }
        h3{
            font-size: 2.3rem;
        }
    }
}
@media(min-width:1299px){
    .aboutUs-container{
        padding: 50px 5vw 1px 5vw;
        max-height: fit-content;
        width:90vw;
        background-image: linear-gradient(to bottom, rgba(0,0,0,1)0%, rgba(0,0,0,.7)10%),
        linear-gradient((to bottom,rgba(0,0,0,.5)80%,rgba(0,0,0,1) )100%) ,
        url('../../utility/backgrounds/olena-sergienko-3BlVILvh9hM-unsplash.jpg');
        background-size:cover;
    h2{
        font-size: 3.5rem;
        color:whitesmoke;
    }
    h3{
        font-size:2.7 ;
    }
    p{  color: whitesmoke;
        font-size:2rem;
        line-height: 6vh;
    }
}
}