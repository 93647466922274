.container {
    display: inline-block;
    cursor: pointer;
  }
  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }
  .change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
  }
  .change .bar2 {opacity: 0;}
  .change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
  }
  .active{
    position: absolute;
  width: 240px;
  height: 340px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  background-color: white;
  top: 90px;
  right: 40px;
  }
  .inactive{
    opacity:0;
    visibility: hidden;
  }