

.galContainer{
    // display: inline-block;
    // justify-content: center;
    h2{ color:white;
        font-size: 3rem;
    }
    text-align: center;
    padding-top:50px;
    position: relative;
    height: fit-content;
    width:100vw;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%,rgba(245, 84, 0, 0.3) 20%), url('../../utility//backgrounds/fruit-basket-agency-caH-ZLrisZA-unsplash.jpg');
    padding-bottom: 20px;
    border-bottom: solid 2px;
}
.gal{
    
    padding: 2px 5px;
    max-height: 500px;
    max-width:95vw;
    margin:1px;
}
.galDiv{

    display: inline-block;
    padding:3px;
    padding-top:7px;
    background-color: rgba(0,0,0,0.8);
    border-radius: 10px;
}
