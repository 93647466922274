.pop{
z-index: 12;
    height: fit-content;
    width:fit-content;
    
    position: absolute;
    
img{
    max-height:100vh;
    max-width:100vw;
}
}