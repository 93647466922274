
.joh{
   box-sizing: border-box;
   display:flex;
    flex-direction: column;
    align-items: center;
    height:100vh;
    width:100vw;
    background-image:
    linear-gradient((to bottom,rgba(0,0,0,.1)80%,rgba(0,0,0,1) )100%) , url('../../utility/backgrounds/background3.jpg');
   //  background-size: cover;
   background-size:150vh;
   background-position:center;
    :hover{
       cursor:default;
      }
      .head{
         padding-top:10%;
         height: fit-content;
         display:block;
         text-align: center;
      }
     
   .serviceAboutContainer{
      
      text-align: center;
      display:flex;
      align-items: center;
      justify-content: center;
      background-color:rgba(0,0,0,0.6) ;
      font-weight: bold;
      width:100vw;
      height:fit-content;
      // padding-right:10%;
      .serviceAbout{
         padding-right:10%;
      }
   }
   li{
      span{
         color:rgb(200, 80, 0);
      }
      list-style: none;
      font-size: 1.3rem;
   }
   h1{ 
   color: #fff;
   text-transform: uppercase;
   font-family: "Josefin Sans", sans-serif;
   background: linear-gradient(to right,rgba(0,0,0, 0.8) 25% ,rgba(115, 46, 0, 0.8) 37%,
   rgba(191, 76, 0, 0.8) 49%,
   rgba(211, 84, 0,0.8) 50%, rgba(191, 76, 0, 0.6) 62%, rgba(35, 14, 0, 0.8) 75%);
   background-size: auto auto;
   background-clip: border-box;
   background-size: 200% auto;
   color: #fff;
   background-clip: text;
   text-fill-color: transparent;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   animation: textclip 2.5s linear infinite;
}
@keyframes textclip {
   to {
      background-position: 200% center;
   }
}
.list-container{
   :hover{
      transform: scaley(1.02);
      cursor: pointer;
      
   }
   position: absolute;
   display:grid;
   left:-40px;
   top:-10px;
   margin-bottom: 0;
   grid-template-columns: repeat(3, 1fr);
   grid-template-rows: repeat(3, 1fr);
   margin-top:auto;
   bottom:0;
   height:22vh;
   width:100vw;
        background-color: rgba(0,0,0,.5);
        font-size:.7rem;
        li{
         display: flex;
         justify-content: center;
         align-items: center;

           span{
            text-align: center;
              font-size:1rem;
               color:rgba(211, 84, 0);}
           border-bottom:solid 1px;
           border-right:solid 1px;
           height:12%;
           font-size:.2rem;
           width:125px;
           list-style: none;
           width:100%;
           height:100%;
           border:solid 1px;
         }       
            align-content: center;
        
   }
   @media(min-width:767px){
      
      .head{
         display:inline;
         padding-top:7%;
         h1{
            font-size: 3rem;
            display: inline;
         }
      }
      .serviceAboutContainer{
         margin-top:10vh;
         background-color: rgba(0,0,0,.6);
         li{
            span{
               font-size: 1.7rem;
            }
         }
      }
      .list-container{
         li{
            background-color: rgba(0,0,0,.3);
            display:flex;
            justify-content: center;
            align-items: center;
            span{
               font-size: 1.5rem;
            }
            :hover{
               transform: scale(1.2);
            }
         }
      }
   }
   @media(min-width:1299px){
      .head{
         padding-top: 5%;
         h1{
            display: inline;
            font-size: 4rem;
         }
      }
      .serviceAboutContainer{
         margin-top:10vh;
         background-color: rgba(0,0,0,.6);
         li{
            font-size: 1.7rem;
         }
      }
      
      .list-container{
         height:20vh;
         li{
            display: flex;
            justify-content: center;
            align-items: center;
            span{
               font-size: 1.2rem;
            }
            :hover{
               transform: scale(1.2);
            }
         }
      }
     
      h1{
   text-transform: uppercase;
   font-weight: 700;
   font-size: 92px;
   display: inline-block;
      }
   
   }
}
@media(min-width:767px){
   .joh{
      background-size:cover;
   }
}
