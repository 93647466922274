

.drop{
    text-align: center;
    left:30%;
    top: -30%;
    position:absolute;
    height:fit-content;
    padding:0 10px 0 10px;
    background:rgba(0, 0, 0, 0.75);
    border-radius:30px;
    width:50%;
    p{
        font-size: 1rem;;
        color: rgba(211, 84, 0);
    }
}

@media(min-width:1299px){
    .drop{
            left: 38%;
            top:-75%;
            p{
            width:25%;
            font-size: 2rem;
        }
    }
}