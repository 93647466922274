.foot{
    position: absolute;
    height:100px;
    width:100%;
    background:rgba(0, 0, 0,0.9);
    margin-top:0;
    .footp{
        color:white;
        text-align: center;
    }
}